import React, { useEffect, useRef } from 'react';

interface ClickOutsideProps {
  onClickOutside: (e: MouseEvent) => void;
  children: React.ReactNode;
  reference?: React.RefObject<HTMLElement>; // Изменили тип на HTMLElement, чтобы поддерживались разные элементы
}

const ClickOutside: React.FC<ClickOutsideProps> = ({ onClickOutside, children, reference }) => {
  const internalRef = useRef<HTMLElement>(null); // Теперь внутренний реф ссылается на HTMLElement
  const wrapperRef = reference || internalRef; // Используем либо внешний реф, либо внутренний

  useEffect(() => {
    const handleClickOutside = (event: MouseEvent) => {
      if (wrapperRef.current && !wrapperRef.current.contains(event.target as Node)) {
        onClickOutside(event);
      }
    };

    document.addEventListener('mousedown', handleClickOutside);

    return () => {
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [onClickOutside, wrapperRef]);

  // Если передали внешний reference, не рендерим обертку <div>
  if (reference) {
    return <>{children}</>;
  }

  // Если не передали reference, используем внутренний элемент
  return <div /*className={styles.backdrop} */ ref={wrapperRef as React.RefObject<HTMLDivElement>}>{children}</div>;
};

export default ClickOutside;
