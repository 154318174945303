import { clsx } from 'clsx';
import React, { useRef, useState } from 'react';
import { Button } from '../Button/Button';
import { Checkbox } from '../Checkbox/Checkbox';
import { Dropdown } from '../Dropdown/Dropdown';
import { Icon } from '../Icon/Icon';
import { TextField } from '../TextField/TextField';

import styles from './SelectField.module.css';

export type OptionValue = string;
export type OptionName = string;

export type Option = {
  value: OptionValue;
  name: OptionName;
};

function sortOptions(options: Option[], values: OptionValue[]) {
  return [...options].sort((a, b) => {
    // Проверяем, есть ли value в массиве values
    const aInValues = values.includes(a.value);
    const bInValues = values.includes(b.value);

    // Если один из value есть в values, а другой нет
    if (aInValues && !bInValues) return -1;
    if (!aInValues && bInValues) return 1;

    // Если оба или ни один из value нет в values, сортируем по name
    return a.name.localeCompare(b.name);
  });
}

type SelectFieldProps = {
  values: OptionValue[];
  options: Option[];
  handleChange: (values: OptionValue[]) => void;
  multiple?: boolean;
};

// TODO добавить строку в допдаун "Выбрать все", сделать чекбоксы по высоте на всю строку, стилизовать, добавить обработку событий клавиатуры
export const SelectField = ({
  values,
  options,
  handleChange,
  multiple = false,
}: SelectFieldProps) => {
  const [isDropdownOpen, setIsDropdownOpen] = useState(false);
  const [filter, setFilter] = useState('');

  const inputRef = useRef<HTMLInputElement>(null);

  const valuesText = values
    .map((value) => options.find((option) => option.value === value)?.name)
    .join(', ');

  const filteredOptions = options.filter(
    (option) =>
      filter === '' ||
      option.name.toLowerCase().includes(filter.toLowerCase())
  );
  const sortedOptions = multiple
    ? sortOptions(
        filteredOptions,
        values
      )
    : filteredOptions;

  const openDropdown = () => {
    setFilter('');
    setIsDropdownOpen(true);
  };

  const handleButtonClick = () => {
    if (isDropdownOpen) {
      closeDropdown();
    } else {
      openDropdown();
      inputRef.current?.focus();
    }
  };

  const handleFocus: React.FocusEventHandler<HTMLInputElement> = () => {
    openDropdown();
  };

  const closeDropdown = () => {
    setIsDropdownOpen(false);
    setFilter('');
  };

  const handleClose = () => {
    closeDropdown();
  };

  const handleChangeItem = (value: OptionValue) => {
    if (multiple) {
      const changedValues = [...values];
      if (changedValues.includes(value)) {
        handleChange(changedValues.filter((cValue) => cValue !== value));
      } else {
        changedValues.push(value);
        handleChange(changedValues);
      }
    } else {
      handleChange([value]);
      handleClose();
    }
  };

  return (
    <Dropdown
      open={isDropdownOpen}
      onClose={handleClose}
      dropdown={
        <div
          style={{
            backgroundColor: 'white',
            border: '1px rgba(224, 230, 237, 1) solid',
          }}
        >
          {sortedOptions.length > 0 ? (
            sortedOptions.map((option) => (
              <div
                key={option.value}
                className={clsx(styles.DDItem, {
                  [styles.Selected]: !multiple && option.value === values?.[0],
                })}
                onClick={() => handleChangeItem(option.value)}
              >
                {multiple && (
                  <Checkbox
                    checked={values.includes(option.value)}
                    handleChange={() => handleChangeItem(option.value)}
                  />
                )}
                <div>{option.name}</div>
              </div>
            ))
          ) : (
            <div
              style={{
                width: '100%',
                height: 36,
                display: 'flex',
                justifyContent: 'center',
                alignItems: 'center',
              }}
            >
              Нет опций
            </div>
          )}
        </div>
      }
    >
      <TextField
        inputRef={inputRef}
        value={isDropdownOpen ? filter : valuesText}
        handleChange={setFilter}
        onFocus={handleFocus}
        endElements={[
          <Button
            onClick={() => handleButtonClick()}
            variant="text"
            iconEnd={
              <Icon name={isDropdownOpen ? 'ChevronUp' : 'ChevronDown'} />
            }
            overrideFillIcon
            style={{
              backgroundColor: isDropdownOpen ? '#D3DCE6' : undefined,
              padding: 6,
              marginRight: 1,
            }}
          />,
        ]}
      />
    </Dropdown>
  );
};
