import React from 'react';
import { NavLayout } from './NavLayout';
import { PageLayout } from './PageLayout';

import styles from './Layout.module.css';


type AppLayoutProps = {
  children: React.ReactNode;
};

export const AppLayout = ({ children }: AppLayoutProps) => {
  return (
    <div className={styles.appRoot}>
      <NavLayout />
      <PageLayout>{children}</PageLayout>
    </div>
  )
}
