import React from 'react';
import { useParams, Link, useNavigate } from 'react-router-dom';
import { CardFormController } from '../components/forms/CardFormController/CardFormController';
import { ButtonIcon } from '../components/uikit/ButtonIcon/ButtonIcon';
import { FormsList } from '../models/list-forms-model';
import { isInt } from '../utils/functions';


export const CardFormPage = () => {
  const navigate = useNavigate();

  const { formCode, id } = useParams();

  const form = FormsList.find((form) => form.code === formCode);

  if (!form) return null;

  if (!(id === 'new' || isInt(id))) {
    setTimeout(() => {
      navigate(`/forms/${form.groupCode}/${form.subGroupCode}/${form.code}`);
    }, 0);
    return null;
  }

  return (
    <>
      <div style={{ height: 48, padding: '6px 24px' }}>
        <Link to={`/forms/${form.groupCode}/${form.subGroupCode}/${form.code}`}>
          {/*<Button variant="additional" iconEnd={<Icon name="ChevronLeft" />} overrideFillIcon rounded />*/}
          <ButtonIcon iconName="ArrowLeft" variant="additional" rounded />
        </Link>
      </div>
      <CardFormController
        form={form}
        id={isInt(id) ? parseInt(id || '') : undefined}
      />
    </>
  );
};
