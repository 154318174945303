import React from 'react';
import { Icon } from '../uikit/Icon/Icon';

import styles from './Layout.module.css';

export const HeaderLayout = () => {
  return (
    <header className={styles.appHeader}>
      <div className={styles.appHeaderBlock}>
        <img src={'/logo192.png'} alt="logo" height={48} />
      </div>
      <div className={styles.appHeaderBlock}>Основная НСИ v</div>
      <div className={styles.appHeaderBlock}>Главная страница</div>
      <div className={styles.appHeaderBlock}>
        <span className={styles.userName}>Петров П.П.</span>
        <div className={styles.userIconWrap}>
          <Icon name={'User'} fill={'white'} size="small" />
        </div>
      </div>
    </header>
  );
};
