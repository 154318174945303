import { useState } from 'react';
import { ColorPicker } from '../components/uikit/ColorPicker/ColorPicker';
import {
  Icon,
  IconsMenu,
  IconsCommon,
  IconsStatus,
  IconsToolbar,
} from '../components/uikit/Icon/Icon';
import { Tooltip } from '../components/uikit/Tooltip/Tooltip';

export const DevIconsPage = () => {
  const [isShowNames, setIsShowNames] = useState(false);
  const [color, setColor] = useState<string>('#3C4858'); // Начальный цвет по умолчанию

  const renderIcons = (icons: any, title: string) => {
    return (
      <>
        <div style={{ margin: '8px 0' }}>{title}</div>
        {(Object.keys(icons) as Array<keyof typeof icons>).map((name: any) => (
          <Tooltip key={name} text={name}>
            <div key={name} style={{ display: 'inline-block', padding: 8 }}>
              <Icon name={name} fill={color}/>
              {isShowNames && <span>{name}</span>}
            </div>
          </Tooltip>
        ))}
      </>
    );
  };

  return (
    <div style={{ padding: 20 }}>
      <div>Иконки</div>
      <div>
        <label>
          Показать имена:{' '}
          <input
            style={{ appearance: 'auto' }}
            type="checkbox"
            checked={isShowNames}
            onChange={() => setIsShowNames(!isShowNames)}
          />
        </label>
      </div>
      <ColorPicker color={color} handleColorChange={setColor} />
      <div style={{ padding: 10 }}>
        <Icon name={'User'} />
        <span>текст после иконок дефолт</span>
      </div>
      <div
        style={{ padding: 10, display: 'inline-flex', alignItems: 'center' }}
      >
        <Icon name={'User'} />
        <span>текст после иконок flex-inline, </span>
      </div>
      <div style={{ padding: 10 }}>
        {renderIcons(IconsMenu, 'Иконки левого меню')}
        {renderIcons(IconsStatus, 'Иконки статуса:')}
        {renderIcons(IconsCommon, 'Интерфейсные иконки:')}
        {renderIcons(IconsToolbar, 'Иконки тулбара:')}
      </div>
    </div>
  );
};
