import { FormDataItem, ListFormData } from '../../../types/common/form';


export const sortRows = (
  listFormData: ListFormData
): FormDataItem[] => {
  const { fields, rows } = listFormData;

  // Шаг 1: Найдем колонку с указанием сортировки
  const sortColumn = fields.find(col => col.sort !== null);

  if (!sortColumn) {
    // Если колонка с сортировкой не найдена, возвращаем массив без изменений
    return rows;
  }

  // Шаг 2: Определяем направление сортировки
  const sortDirection = sortColumn.sort === 'ASC' ? 1 : -1;

  // Шаг 3: Сортируем массив строк
  return [...rows].sort((a, b) => {
    const aValue = a[sortColumn.code];
    const bValue = b[sortColumn.code];

    // Обрабатываем строки и числа по-разному
    if (typeof aValue === 'string' && typeof bValue === 'string') {
      return aValue.localeCompare(bValue) * sortDirection;
    } else if (typeof aValue === 'number' && typeof bValue === 'number') {
      return (aValue - bValue) * sortDirection;
    }

    // В остальных случаях возвращаем 0
    return 0;
  });
}
