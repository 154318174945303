import { clsx } from 'clsx';
import React from 'react';

import styles from './Button.module.css';

export type ButtonProps = React.ButtonHTMLAttributes<HTMLButtonElement> & {
  children?: React.ReactNode;
  text?: string;
  variant?: 'primary' | 'secondary' | 'additional' | 'text';
  iconStart?: React.ReactNode;
  iconEnd?: React.ReactNode;
  rounded?: boolean;
  overrideFillIcon?: boolean;
};

export const Button = ({
  children,
  text,
  variant = 'primary',
  iconStart,
  iconEnd,
  rounded,
  overrideFillIcon,
  ...buttonProps
}: ButtonProps) => {
  const haveContent = Boolean(text || children);

  const className = clsx(styles.button, {
    [styles.overrideFillIcon]: overrideFillIcon,
    [styles.primary]: variant === 'primary',
    [styles.secondary]: variant === 'secondary',
    [styles.additional]: variant === 'additional',
    [styles.invisible]: variant === 'text',
    [styles.onlyIcon]: !haveContent,
    [styles.rounded]: rounded,
  });

  return (
    <button className={className} {...buttonProps}>
      {iconStart && (
        <span className={clsx({ [styles.iconMarginRight]: haveContent })}>
          {iconStart}
        </span>
      )}
      {text || children}
      {iconEnd && (
        <span className={clsx({ [styles.iconMarginLeft]: haveContent })}>
          {iconEnd}
        </span>
      )}
    </button>
  );
};
