import React from 'react';
import { HeaderLayout } from './HeaderLayout';

import styles from './Layout.module.css';


type PageLayoutProps = {
  children: React.ReactNode;
};

export const PageLayout = ({ children }: PageLayoutProps) => {
  return (
    <div className={styles.appPages}>
      <HeaderLayout />
      <main className={styles.appMain}>
        {children}
      </main>
    </div>
  )
}
