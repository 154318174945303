import React from 'react';
import { IForm } from '../../../models/list-forms-model';
import { FieldType } from '../../../types/common/form';
import { Option, SelectField } from '../../uikit/SelectField/SelectField';
import { TextField } from '../../uikit/TextField/TextField';
import styles from './CardForm.module.css';

export interface FormField {
  code: string;
  label: string;
  type: FieldType;
  oldValue: string | null;
  value: string | null;
  options?: Option[];
}

type CardFormSimpleProps = {
  form: IForm;
  fields: FormField[];
  setFieldValue: (fieldCode: string, value: string | null) => void;
  isReadOnly: boolean;
};

export const CardFormSimple = ({
  form,
  fields,
  setFieldValue,
  isReadOnly,
}: CardFormSimpleProps) => {
  const getValueReadonly = (field: FormField) => {
    switch (field.type) {
      case 'Enum':
      case 'Dictionary': {
        const valueText =
          field.options?.find((option) => option.value === field.value)?.name ||
          field.value;
        return valueText;
      }
      default:
        return field.value;
    }
  };

  const getValueInput = (field: FormField) => {
    switch (field.type) {
      case 'Enum':
      case 'Dictionary': {
        return (
          <SelectField
            values={field.value ? [field.value] : []}
            options={field.options || []}
            handleChange={(values) =>
              setFieldValue(field.code, values?.[0] || null)
            }
          />
        );
      }
      default:
        return (
          <TextField
            value={field.value ?? ''}
            handleChange={(value) => setFieldValue(field.code, value)}
          />
        );
    }
  };

  return (
    <div className={styles.CardForm}>
      <div className={styles.Header}>{form.name}</div>
      <div className={styles.Form}>
        <div className={styles.Container}>
          {fields.map((field) => (
            <div key={field.code} className={styles.FieldContainer}>
              <div className={styles.LabelWrap}>{field.label}:</div>
              <div className={styles.InputWrap}>
                {isReadOnly ? (
                  <div className={styles.Value}>{getValueReadonly(field)}</div>
                ) : (
                  getValueInput(field)
                )}
              </div>
            </div>
          ))}
        </div>
      </div>
    </div>
  );
};
