import React from 'react';

export const ColorPicker = ({color, handleColorChange}: {color: string, handleColorChange: Function}) => {
  // const [color, setColor] = useState<string>('#ffffff'); // Начальный цвет по умолчанию

  const onColorChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    handleColorChange(event.target.value);
  };

  return (
    <div style={{ display: 'flex', alignItems: 'center'  }}>
      <input
        type="color"
        value={color}
        onChange={onColorChange}
        style={{}}
      />
      <p>Выбранный цвет: {color}</p>
    </div>
  );
};

