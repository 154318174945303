import React from 'react';
import ReactDOM from 'react-dom/client';
import App from './App';
import { MetaDataProvider } from './propviders/MetadataPropvider';

console.log('REACT_APP_API_SERVICE', process.env.REACT_APP_API_SERVICE);
console.log('REACT_APP_API_PROXY_TO', process.env.REACT_APP_API_PROXY_TO);

const root = ReactDOM.createRoot(
  document.getElementById('root') as HTMLElement
);
root.render(
  <React.StrictMode>
    <MetaDataProvider>
      <App />
    </MetaDataProvider>
  </React.StrictMode>
);
