export const fromDashToCapitalize = (s: string) => {
    return (s||'').toLowerCase().replace(/(\b|-)\w/g, function(m) {
        return m.toUpperCase().replace(/-/,'');
    });
}

export function isInt(value: string | number | undefined): boolean {
    if (value === undefined) return false;
    // Проверка, если значение является числом
    if (typeof value === 'number') {
        return Number.isInteger(value);
    }

    // Если значение - строка, проверяем, что оно не пустое или не состоит только из пробелов
    if (value.trim() !== '') {
        const parsedValue = Number(value);
        return !isNaN(parsedValue) && Number.isInteger(parsedValue);
    }
    return false;
}

export function isNumeric(value: string | number | undefined): boolean {
    if (value === undefined) return false;
    // Проверка, если значение является числом
    if (typeof value === 'number') {
        return !isNaN(value); // Если это число и не NaN, то оно числовое
    }

    // Если значение - строка, проверяем, что оно не пустое или не состоит только из пробелов
    if (value.trim() !== '') {
        const parsedValue = Number(value);
        return !isNaN(parsedValue); // Возвращаем true, если строка может быть преобразована в число
    }
    return false;
}

export function uniqBy<T, K>(array: T[], keySelector: (item: T) => K): T[] {
    const seenKeys: K[] = []; // Массив для хранения уникальных ключей
    const uniqueArray: T[] = []; // Массив для хранения уникальных элементов

    for (const item of array) {
        const key = keySelector(item); // Получаем ключ с помощью переданной функции
        if (seenKeys.indexOf(key) === -1) { // Проверяем, если такого ключа ещё не было
            seenKeys.push(key); // Запоминаем новый уникальный ключ
            uniqueArray.push(item); // Добавляем элемент в уникальный массив
        }
    }
    return uniqueArray;
}

// Универсальная функция с возможностью указания поля для ключей
export function transformArrayToObject<T extends Record<string, any>>(
  keyField: keyof T, // Указываем поле, по которому будет строиться объект
  arr: T[],
): Record<string, T> {
    return arr.reduce((acc, item) => {
        acc[item[keyField]] = item;
        return acc;
    }, {} as Record<string, T>);
}

export function isEmptyObject(obj: Object) {
    for (let i in obj) {
        if (obj.hasOwnProperty(i)) {
            return false;
        }
    }
    return true;
}
