import axios from 'axios';

const TIMEOUT_SERVER = 30 * 1000 * 60; // 30 minutes

const developmentHeaders = process.env.NODE_ENV === 'development' ? {'X-Api-Request': ''} : {};

const params = {
  timeout: TIMEOUT_SERVER,
  withCredentials: false,
  headers: {
    'Content-Type': 'application/json; charset=utf-8',
    ...developmentHeaders,
  },
};

export const api = axios.create({
  baseURL: process.env.REACT_APP_API_SERVICE,
  ...params,
});
