import {
  CSSProperties,
  MouseEvent,
  MouseEventHandler,
  useContext,
  useRef,
  useState,
} from 'react';
import {
  MetadataContext,
  MetaEnums,
  MetaFields,
} from '../../../propviders/MetadataPropvider';
// import { useVirtualizer } from '@tanstack/react-virtual'
import {
  FormDataItem,
  ListFormData,
  SchemaField /*SetFieldProp*/,
} from '../../../types/common/form';
import { Checkbox } from '../../uikit/Checkbox/Checkbox';
import { Icon } from '../../uikit/Icon/Icon';
import { sortRows } from './helper';

import styles from './ListFormTable.module.css';

const CHECKBOX_COL_WIDTH = 36;

const checkboxCellStyle: CSSProperties = {
  position: 'sticky',
  left: 0,
  paddingLeft: 4,
  width: CHECKBOX_COL_WIDTH,

  minWidth: CHECKBOX_COL_WIDTH,
  maxWidth: CHECKBOX_COL_WIDTH,
};

const getFieldValue = (
  field: SchemaField,
  row: FormDataItem,
  metaFields: MetaFields,
  metaEnums: MetaEnums
) => {
  const value = row[field.code];

  if (field.type === 'Enum') {
    const dict = metaFields[field.code]?.dict;
    const enumValue = metaEnums[dict]?.values[value as string];
    return enumValue ? enumValue.localName : value;
  }
  if (field.type === 'Boolean') {
    return <Checkbox checked={value as boolean} size="small" />;
  }
  return value;
};

type ListFormTableProps = {
  listFormData: ListFormData;
  selectRow: (key: number, value: boolean) => void;
  // setFieldProp: SetFieldProp;
  setSortField: (code: string) => void;
  handleDoubleRecordClick: (id: string) => void;
};

export const ListFormTable = ({
  listFormData,
  selectRow,
  // setFieldProp,
  setSortField,
  handleDoubleRecordClick,
}: ListFormTableProps) => {
  const { metaFields, metaEnums } = useContext(MetadataContext);

  const { fields } = listFormData;
  const sortedRows = sortRows(listFormData);

  const [widthFirst, setWidthFirst] = useState<number>(150);
  const startX = useRef(0);
  const startWidth = useRef(0);
  const firstStickyRef = useRef<HTMLTableHeaderCellElement>(null);

  // Начало изменения размера
  const handleStartResize: MouseEventHandler<HTMLDivElement> = (event) => {
    event.preventDefault();

    startX.current = event.pageX;
    startWidth.current = firstStickyRef.current?.offsetWidth || 0;

    // Функция для изменения ширины столбца
    const resizeColumn: any = (event: MouseEvent) => {
      const newWidth = startWidth.current + (event.pageX - startX.current);
      setWidthFirst(newWidth);
    };

    // Остановка изменения размера
    const stopResizing = () => {
      document.removeEventListener('mousemove', resizeColumn);
      document.removeEventListener('mouseup', stopResizing);
    };

    document.addEventListener('mousemove', resizeColumn);
    document.addEventListener('mouseup', stopResizing);
  };

  return (
    <div
      style={{ width: '100%', height: 'calc(100% - 66px)', overflow: 'auto' }}
    >
      <table className={styles.table}>
        <thead>
          <tr>
            <th style={checkboxCellStyle}>
              <Checkbox
                size="small"
                disabled
                checked={false}
                handleChange={() => {}}
              />
            </th>
            {fields.map((field, index) => {
              return (
                <th
                  key={field.code}
                  ref={firstStickyRef}
                  style={
                    index === -1 // заглушка
                      ? {
                          position: 'sticky',
                          left: CHECKBOX_COL_WIDTH,
                          width: widthFirst,
                          minWidth: widthFirst,
                        }
                      : {}
                  }
                  onClick={() =>
                    // setFieldProp(field.code, 'name', field.name.toUpperCase())
                    setSortField(field.code)
                  }
                >
                  {metaFields[field.code]?.localName || field.code}

                  {field.sort
                    ? {
                        ASC: <Icon name={'SortDown'} size="small" />,
                        DESC: <Icon name={'SortUp'} size="small" />,
                      }[field.sort]
                    : null}

                  {index === -1 && (
                    <div
                      style={{
                        position: 'absolute',
                        right: 0,
                        top: 0,
                        width: 5,
                        height: '100%',
                        cursor: 'col-resize',
                        backgroundColor: 'red',
                      }}
                      onMouseDown={handleStartResize}
                    ></div>
                  )}
                </th>
              );
            })}
          </tr>
        </thead>
        <tbody>
          {sortedRows.map((row) => (
            <tr
              key={row._key}
              onDoubleClick={() => handleDoubleRecordClick(String(row.id))}
            >
              <td style={checkboxCellStyle}>
                <Checkbox
                  size="small"
                  checked={row._selected}
                  handleChange={() => selectRow(row._key, !row._selected)}
                />
              </td>
              {fields.map((field, index) => {
                return (
                  <td
                    key={field.code}
                    style={
                      index === -1
                        ? {
                            position: 'sticky',
                            left: CHECKBOX_COL_WIDTH,
                            width: widthFirst,
                            minWidth: widthFirst,
                          }
                        : {}
                    }
                  >
                    {getFieldValue(field, row, metaFields, metaEnums)}
                  </td>
                );
              })}
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
};
