import React, { useEffect, useRef, useState } from 'react';
import Portal from '../Portal/Portal';

const TOOLTIP_OFFSET = 8;

type TooltipProps = {
  text: string;
  children: React.ReactNode;
  delay?: number;
};

export const Tooltip: React.FC<TooltipProps> = ({
  text,
  children,
  delay = 300,
}) => {
  const [visible, setVisible] = useState(false);
  const [timeoutId, setTimeoutId] = useState<NodeJS.Timeout | null>(null);

  const ref = useRef<HTMLDivElement>(null);
  const tooltipRef = useRef<HTMLDivElement>(null);

  const { top, left, width } = (ref.current &&
    ref.current.getBoundingClientRect()) || { top: 0, left: 0, width: 0 };

  const containerStyles: React.CSSProperties = {
    position: 'relative',
    display: 'inline-block',
  };

  const tooltipStyles: React.CSSProperties = {
    position: 'fixed',
    zIndex: 1,
    top: top,
    left: (left || 0) + (width || 0) / 2,

    padding: '8px',
    backgroundColor: '#1F2D3D',
    color: 'white',
    borderRadius: '2px',
    whiteSpace: 'nowrap',
    transform: `translateX(-50%) translateY(calc(-100% - ${TOOLTIP_OFFSET}px)`,
    opacity: 0,
    transition: 'opacity 0.3s ease',
  };
  // для анимации появления
  useEffect(() => {
      setTimeout(() => {
        if (tooltipRef.current?.style) {
          tooltipRef.current.style.opacity = visible ? '1' : '0';
        }
      },1);
  }, [visible])

  // Стили для стрелки
  const arrowStyles: React.CSSProperties = {
    position: 'absolute',
    width: '0',
    height: '0',
    borderStyle: 'solid',
    zIndex: 1000,
    transform: 'translateX(-50%) rotate(180deg)',
    borderWidth: '0 8px 8px 8px',
    borderColor: 'transparent transparent #1F2D3D transparent',
    top: '100%',
    left: '50%',
  };

  const showTooltip = () => {
    const id = setTimeout(() => {
      setVisible(true);
    }, delay);
    setTimeoutId(id);
  };

  const hideTooltip = () => {
    if (timeoutId) {
      clearTimeout(timeoutId);
    }
    setVisible(false);
  };
  return (
    <div
      ref={ref}
      style={containerStyles}
      onMouseEnter={showTooltip}
      onMouseLeave={hideTooltip}
    >
      {children}
      {visible && (
        <Portal>
          <div style={tooltipStyles} ref={tooltipRef}>
            {text}
            <div style={arrowStyles}></div> {/* Стрелка */}
          </div>
        </Portal>
      )}
    </div>
  );
};
