import React from 'react';
import { Button, ButtonProps } from '../Button/Button';
import { Icon, IconName } from '../Icon/Icon';

type ButtonIcon = ButtonProps & {
  iconName: IconName;
};

export const ButtonIcon = ({
  iconName,
  overrideFillIcon = true,
  rounded = false,
  ...buttonProps
}: ButtonIcon) => {
  return (
    <Button
      variant="text"
      iconEnd={<Icon name={iconName} />}
      overrideFillIcon
      rounded={rounded}
      {...buttonProps}
    />
  );
};
