import { MetaColumnFieldTypes, MetaFields } from '../../propviders/MetadataPropvider';
import {
  FieldType, FormDataItem,
  FromMetadata,
  SchemaField,
} from '../../types/common/form';


export const ServiceFields = [
  'id',
  'createBy',
  'created',
  'changeBy',
  'changed',
  'externalId',
  'ldelete',
  'isNew',
];
export const ReadOnlyFields = [
  'fullName',
];

export const getSchema = (data: FormDataItem[], metaFields: MetaFields): SchemaField[] => {
  if (data.length === 0) return [];

  // Получаем первый объект для анализа структуры
  const firstItem = data[0];
  const schema: SchemaField[] = [];

  Object.keys(firstItem).forEach((key) => {
    if (MetaColumnFieldTypes.includes(metaFields[key]?.type || 'String')) {
      schema.push({
        code: key,
        type: metaFields[key]?.type as FieldType || 'String',
        filter: [],
        sort: null,
        pinned: null,
        width: null,
      });
    }
  });

  return schema;
};

export const getSchemaCard = (metadata: FromMetadata, metaFields: MetaFields): SchemaField[] => {
  const schema: SchemaField[] = [];

  Object.keys(metadata.fields).forEach((key) => {
    if (MetaColumnFieldTypes.includes(metaFields[key]?.type) && !ServiceFields.includes(key)) {
      schema.push({
        code: key,
        type: metaFields[key]?.type as FieldType || 'String',
        filter: [],
        sort: null,
        pinned: null,
        width: null,
      });
    }
  });

  return schema;
};

export const validateStorage = (
  fields: SchemaField[],
  storageFields: SchemaField[] | null
): boolean => {
  if (storageFields === null || fields.length !== storageFields.length) {
    return false;
  }

  for (let i = 0; i < fields.length; i++) {
    if (
      !storageFields.find(
        (sField) =>
          sField.code === fields?.[i].code && sField.type === fields?.[i].type
      )
    ) {
      return false;
    }
  }

  return true;
};
