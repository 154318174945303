import React, { CSSProperties, useRef } from 'react';
import ClickOutside from '../ClickOutside/ClickOutside';
import Portal from '../Portal/Portal';

type DropdownProps = {
  open: boolean;
  onClose: () => void;
  dropdown: React.ReactNode;
  children: React.ReactNode;
};

export const Dropdown = ({
  open,
  onClose,
  children,
  dropdown,
}: DropdownProps) => {
  const containerRef = useRef<HTMLDivElement>(null);
  const anchorRef = useRef<HTMLDivElement>(null);

  const { left, width, bottom } =
    anchorRef.current?.getBoundingClientRect() || {
      top: 0,
      left: 0,
      width: 0,
      bottom: 0,
    };

  const styleContainer: CSSProperties = {
    position: 'fixed',
    zIndex: 1,
    left,
    top: bottom,
    width,
  };

  const handleClose = (event: MouseEvent) => {
    if (!containerRef.current?.contains(event.target as Node)) {
      onClose()
    }
  }

  return (
      <div ref={containerRef}>
        <div ref={anchorRef}>{children}</div>
        {open && (
          <Portal>
            <ClickOutside onClickOutside={handleClose}>
              <div style={styleContainer}>{dropdown}</div>
            </ClickOutside>
          </Portal>
        )}
      </div>
  );
};
