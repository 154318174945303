import React from 'react';
import { BrowserRouter, Routes, Route } from 'react-router-dom';
import { AppLayout } from './components/layout/AppLayout';
import { CardFormPage } from './pages/CardFormPage';
import { MainPage } from './pages/MainPage';
import { NoMatchPage } from './pages/NoMatchPage';
import { ListFormPage } from './pages/ListFormPage';
import { DevUiKitPage } from './pages/DevUiKitPage';
import { DevIconsPage } from './pages/DevIconsPage';

function App() {

  return (
    <BrowserRouter>
      <AppLayout>
        <Routes>
          <Route path="/" element={<MainPage />} />
          <Route path="/nsi" element={<ListFormPage />} />
          <Route path="/icons" element={<DevIconsPage />} />
          <Route path="/uikit" element={<DevUiKitPage />} />
          <Route path="/forms/:groupCode/:subGroupCode/:formCode" element={<ListFormPage />} />
          <Route path="/forms/:groupCode/:formCode" element={<ListFormPage />} />
          <Route path="/forms/:groupCode/:subGroupCode/:formCode/:id" element={<CardFormPage />} />
          <Route path="*" element={<NoMatchPage />} />
        </Routes>
      </AppLayout>
    </BrowserRouter>
  )
}

export default App;
