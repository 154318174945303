import { FormDataItem, ListFormDataResponse } from '../types/common/form';
import { api } from '../utils/api';

export const apiGetDict = async (
  service: string,
  dict: string,
  params?: string
): Promise<ListFormDataResponse> => {
  return (await api.get(`/api/${service}/${dict}${params ? `?${params}` : ''}`))
    .data;
};
export const apiGetRecord = async (
  service: string,
  dict: string,
  id: string
): Promise<FormDataItem> => {
  return (await api.get(`/api/${service}/${dict}/${id}`)).data;
};

export const apiCreateDictRecord = async (
  service: string,
  dict: string,
  record: Record<string, any>,
): Promise<FormDataItem> => {
  return (await api.post(`/api/${service}/${dict}`, record)).data;
};

export const apiUpdateDictRecord = async (
  service: string,
  dict: string,
  id: string,
  record: Record<string, any>,
) => {
  return (await api.put(`/api/${service}/${dict}/${id}`, record)).data;
};


export const apiDeleteDictRecord = async (
  service: string,
  dict: string,
  id: string,
) => {
  return (await api.patch(`/api/${service}/${dict}/${id}`)).data;
};

