import { useParams } from 'react-router-dom';
import { ListFormController } from '../components/forms/ListFormController/ListFormController';
import { FormsList } from '../models/list-forms-model';

export const ListFormPage = () => {

  const { formCode } = useParams();

  const form = FormsList.find((form) => form.code === formCode);

  if (!form) return null;

  return (
    <ListFormController form={form} />
  );
}
