import { useCallback, useContext, useEffect, useState } from 'react';
import { IForm } from '../../models/list-forms-model';
import { MetadataContext } from '../../propviders/MetadataPropvider';
import { apiGetDict } from '../../servises/dict';
import { ListFormData, SchemaField } from '../../types/common/form';
import { isEmptyObject } from '../../utils/functions';
import {
  formFieldStorage,
  getFormFieldStorageKey,
} from '../../utils/localStorage';
import { getSchema, getSchemaCard, validateStorage } from './helper';


type UseFormSchemaAndDataProps = {
  form: IForm;
};

export const useFormSchemaAndData = ({ form }: UseFormSchemaAndDataProps) => {
  const { serviceCode: service, code, api, urlParams } = form;

  const { isLoadingMetadata, metaFields } = useContext(MetadataContext);

  const [isLoading, setIsLoading] = useState(false);
  const [formData, setFormData] = useState<
    ListFormData & { service: string; code: string, fieldsForCardForm: SchemaField[] }
  >({ rows: [], fields: [], service, code, fieldsForCardForm: [] });

  // save fields to localStorage
  useEffect(() => {
    if (formData.fields.length) {
      formFieldStorage.add(
        getFormFieldStorageKey(formData.service, formData.code),
        formData.fields
      );
    }
  }, [formData]);

  const fetchDict = useCallback(() => {
    if (isEmptyObject(metaFields)) return;
    setIsLoading(true);
    apiGetDict(service, api || code, urlParams)
      .then((resp) => {
        let fields: SchemaField[] = getSchema(resp.data, metaFields);
        let fieldsForCardForm: SchemaField[] = getSchemaCard(resp.metadata, metaFields);

        const storageFields = formFieldStorage.get(
          getFormFieldStorageKey(service, code)
        );

        if (
          storageFields &&
          storageFields.length &&
          validateStorage(fields, storageFields)
        ) {
          fields = storageFields;
        } else {
          formFieldStorage.add(getFormFieldStorageKey(service, code), fields);
        }

        setFormData({
          service,
          code: code,
          fields,
          fieldsForCardForm,
          rows: resp.data.map((row, index) => ({
            ...row,
            _key: index,
            _selected: false,
          })),
        });
      })
      .finally(() => {
        setIsLoading(false);
      });
  }, [api, code, metaFields, service, urlParams]);

  useEffect(() => {
    fetchDict();
  }, [fetchDict]);

  return {
    formData,
    setFormData,
    fetchDict,
    isLoading: isLoading || isLoadingMetadata,
  };
};
